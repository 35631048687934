import React from 'react';

const AirportPopup = ({ name, randomPrice }) => {
  return (
    <div className={'bg-red rounded-md shadow-md border max-w-xs'}>
      <div>
        <span className="text-gray-900 font-normal text-[12px] m-0 leading-none">{name}</span>
        <div style={{ color: 'gray', fontSize: '14px', fontWeight: 'bold', marginTop: '5px' }}>{randomPrice}€</div>
      </div>
    </div>
  );
};

export default AirportPopup;