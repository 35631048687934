import React, { useEffect } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import ReactDOMServer from 'react-dom/server';
import AirportPopup from './components/AirportPopup';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || 'your_fallback_token_here';

function App() {
  const fetchUserLocation = async () => {
    if (navigator.geolocation) {
      return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              longitude: position.coords.longitude,
              latitude: position.coords.latitude,
            });
          },
          () => {
            resolve({ longitude: -3.7038, latitude: 40.4168 }); // Default to Madrid
          }
        );
      });
    } else {
      console.warn('Geolocation is not supported by this browser.');
      return { longitude: -3.7038, latitude: 40.4168 }; // Default to Madrid
    }
  };

  useEffect(() => {
    let map; // Declare map variable

    const initializeMap = async () => {
      const mapContainer = document.getElementById('map');
      if (!mapContainer) {
        console.error("Map container 'map' not found in the DOM.");
        return;
      }

      const userLocation = await fetchUserLocation();

      map = new mapboxgl.Map({
        container: 'map',
        center: [userLocation.longitude, userLocation.latitude],
        zoom: 2.64, // Set initial zoom level
        projection: 'mercator',
        style: 'mapbox://styles/zergio/cm6cx5qf9007l01sa0uq1gnco',
      });

      map.on('load', () => {
        map.addSource('airports', {
          type: 'vector',
          url: 'mapbox://zergio.8ptqbbqw',
        });

        // Add a circle layer for airports
        map.addLayer({
          id: 'airport-circles',
          type: 'circle',
          source: 'airports',
          'source-layer': 'airports_tileset-2co9xl',
          paint: {
            'circle-radius': 6,
            'circle-color': '#4169e1',
            'circle-stroke-width': 2,
            'circle-stroke-color': '#ffffff',
          },
          filter: ['>=', ['get', 'routes_count'], 500], // Initial filter for low zoom level
        });

        // Add a new layer for selected circles
        map.addLayer({
          id: 'selected-airport-circles',
          type: 'circle',
          source: 'airports',
          'source-layer': 'airports_tileset-2co9xl',
          paint: {
            'circle-radius': 8,
            'circle-color': '#ff00ff',
            'circle-stroke-width': 2,
            'circle-stroke-color': '#ffffff',
          },
          filter: ['==', 'name', ''], // Initially, no circle is selected
        });

        // Handle clicks to select a circle
        map.on('click', 'airport-circles', (e) => {
          const selectedName = e.features[0].properties.display_name;

          // Update the filter for the selected circle layer
          map.setFilter('selected-airport-circles', ['==', 'name', selectedName]);

          const coordinates = e.features[0].geometry.coordinates.slice();

          new mapboxgl.Popup({ closeButton: false })
            .setLngLat(coordinates)
            .setHTML(
              ReactDOMServer.renderToString(
                <AirportPopup
                  name={selectedName}
                  randomPrice={Math.floor(Math.random() * (1908 - 21 + 1)) + 21}
                />
              )
            )
            .addTo(map);
        });

        // Change the cursor to a pointer when hovering over circles
        map.on('mouseenter', 'airport-circles', () => {
          map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', 'airport-circles', () => {
          map.getCanvas().style.cursor = '';
        });

        // Function to update filters based on zoom level
        const updateFilters = () => {
          const zoom = map.getZoom();
          console.log(zoom);
          let minRoutes = 0;

          if (zoom < 4.21) {
            minRoutes = 600; // Show only major airports
          } else {
            minRoutes = 1; // Show all airports
          }

          map.setFilter('airport-circles', ['>=', ['get', 'routes_count'], minRoutes]);
        };

        updateFilters(); // Apply filters on initial load
        map.on('zoomend', updateFilters); // Apply filters on zoom changes
      });
    };

    initializeMap();

    return () => {
      if (map) {
        map.remove();
      }
    };
  }, []);

  return <div id="map" style={{ width: '100vw', height: '100vh' }} />;
}

export default App;
